const initNarrowText = () => {
  const text = document.querySelectorAll(".narrow-text-section .l-wrap > *");

  if (text) {
    text.forEach((span, index) => {
      gsap.from(span, {
        opacity: 0,
        y: "100px",
        delay: index / 10,
        scrollTrigger: {
          trigger: span,
          start: "top 90%",
          end: "bottom bottom",
          toggleActions: "play none none reverse",
          once: true,
        },
      });
    });
  }

};

document.addEventListener("DOMContentLoaded", function () {
  initNarrowText();
});
